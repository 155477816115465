import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

const COLORS = {
  CYAN: "cyan",
  PURPLE: "purple",
}

const colorSolver = (color, inverted, theme) => {
  switch (color) {
    case COLORS.PURPLE:
      return theme.color.purple
    case COLORS.CYAN:
      return theme.color.cyan_500
    default:
      return inverted ? theme.color.text.inverted : theme.color.text.default
  }
}

const StyledText = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ theme, inverted, color }) => colorSolver(color, inverted, theme)};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
  margin: ${({ margin }) => margin};
  text-decoration: ${({ underline }) => underline && "underline"};
  text-align: ${({ align }) => align};
  font-family: ${({ fat, theme }) => (fat ? theme.font.fatfrank : theme.font.azo_sans_web)};

  p {
    margin: 0 0 1rem;
  }
`

const Text = ({ element, fontSize, inverted, uppercase, bold, color, margin, underline, ...props }) => (
  <StyledText
    as={element}
    bold={bold}
    color={color}
    fontSize={fontSize}
    inverted={inverted}
    margin={margin}
    underline={underline}
    uppercase={uppercase}
    {...props}
  />
)

Text.defaultProps = {
  element: "p",
  fontSize: "1.4rem",
  inverted: false,
  uppercase: false,
  bold: false,
  color: undefined,
  margin: undefined,
  underline: undefined,
  align: "left",
}

Text.propTypes = {
  element: PropTypes.oneOf(["p", "a", "span", "h1", "h2", "h3", "h4"]),
  fontSize: PropTypes.string,
  inverted: PropTypes.bool,
  uppercase: PropTypes.bool,
  bold: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(COLORS)),
  margin: PropTypes.string,
  underline: PropTypes.bool,
  align: PropTypes.oneOf(["center", "left", "right"]),
}

export default Text
