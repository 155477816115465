import React, { createContext, useCallback, useContext, useState } from "react"

import PropTypes from "prop-types"

const GlobalDataContext = createContext({})

const { Provider } = GlobalDataContext

const GlobalDataProvider = ({ children }) => {
  const [letsTalkStick, setLetsTalkStick] = useState(false)
  const setLetsTalkStickOn = useCallback(() => setLetsTalkStick(true), [])
  const setLetsTalkStickOff = useCallback(() => setLetsTalkStick(false), [])

  const value = {
    letsTalk: { isStick: letsTalkStick, setLetsTalkStickOn, setLetsTalkStickOff },
  }

  return <Provider value={value}>{children}</Provider>
}

GlobalDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useGlobalDataContext = () => useContext(GlobalDataContext)

export default GlobalDataProvider
