import React from "react"

import styled from "styled-components"

const StyledLower = styled.div`
  ${({ breakpoint }) => `
  display: none;
    @media (max-width: ${breakpoint}px) {
      display: contents;
    }
  `}
`

const StyledUpper = styled.div`
  ${({ breakpoint }) => `
  display: none;
    @media (min-width: ${breakpoint + 1}px) {
      display: contents;
    }
  `}
`

const WindowWidthTernaryConditional = ({ breakpoint, lower, upper }) => (
  <>
    <StyledLower breakpoint={breakpoint}>{lower}</StyledLower>
    <StyledUpper breakpoint={breakpoint}>{upper}</StyledUpper>
  </>
)

WindowWidthTernaryConditional.defaultProps = { breakpoint: 890 }

WindowWidthTernaryConditional.propTypes = {}

export default WindowWidthTernaryConditional
