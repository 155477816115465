import React from "react"

import { NotificationContainer, NotificationManager } from "react-notifications"

export const createNotification = (type, message) => () => {
  switch (type) {
    case "success":
      NotificationManager.success("Success", message, 4000)
      break
    case "error":
      NotificationManager.error("Error message", message, 4000)
      break
    default:
      NotificationManager.success("Success message", message, 4000)
      break
  }
}

const Notifications = () => <NotificationContainer />

export default Notifications
