import BackgroundImage from "gatsby-background-image"
import { animated } from "react-spring"
import styled, { keyframes } from "styled-components"

import LogoJmr from "assets/svg/logo_jmr.svg"

const pulse = keyframes`
  from { transform: scale(1) }
  to { transform: scale(1.1) }
`

const StyledFilter = styled.div`
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(1px);
  z-index: -1;

  ${({ theme }) => theme.mq.xs} {
    backdrop-filter: none;
    background: rgba(0, 0, 0, 0.2);
  }
`

const StyledHero = styled(BackgroundImage)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
`

const StyledMobileWrapper = styled.div`
  ${({ theme }) => theme.mq.xs} {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

const StyledHeroContentBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 110px 0 80px 0;
  ${({ theme }) => theme.mq.xs} {
    padding: 1rem 2rem;
  }
`

const StyledHeroContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5rem;
  align-items: end;
  justify-items: end;

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: auto;
    align-items: center;
    justify-items: center;
    grid-gap: 2rem;
  }
`

const StyledLogo = styled(LogoJmr)`
  display: block;
  height: 90px;
  margin-bottom: 40px;
  margin-top: 20px;
  ${({ theme }) => theme.mq.m} {
    height: 70px;
  }
  ${({ theme }) => theme.mq.s} {
    display: none;
  }
`

const StyledHeroDescription = styled.div`
  color: ${({ theme }) => theme.color.text.inverted};

  h1 {
    font-size: 84px;
    line-height: 92px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.fatfrank};
    margin-bottom: 40px;
    text-transform: uppercase;
    ${({ theme }) => theme.mq.m} {
      font-size: 70px;
      line-height: 78px;
    }     
    ${({ theme }) => theme.mq.s} {
      font-size: 54px;
      line-height: 62px;
      margin-bottom: 24px;
    }
    ${({ theme }) => theme.mq.xs} {
      font-size: 36px;
      line-height: 42px;
    }
  }

  h2 {
    font-size: 24px;
    line-height: 24px;
    ${({ theme }) => theme.mq.s} {
      font-size: 20px;
      line-height: 20px;
    }
    ${({ theme }) => theme.mq.xxs} {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
    }

    &:first-of-type {
      font-family: ${({ theme }) => theme.font.azo_sans_web};
      margin-bottom: 25px;
      ${({ theme }) => theme.mq.s} {
        margin-bottom: 24px;
      }
      ${({ theme }) => theme.mq.xxs} {
        margin-bottom: 16px;
      }
    }
  }
}
`

const StyledGatsbyImg = styled.div`
  display: block;
  width: 140px;
  ${({ theme }) => theme.mq.s} {
    width: 100px;
  }
  ${({ theme }) => theme.mq.xxs} {
    width: 80px;
  }
`

const StyledVideo = styled.video`
  z-index: -1;
  position: absolute;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`

const StyledLetsTalk = styled.div`
  z-index: ${({ theme }) => theme.zIndex.header};
  background: linear-gradient(90deg, #ffbb00 0%, #ffd800 50%);
  color: ${({ theme }) => theme.color.text.inverted};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  animation: ${pulse} 0.8s linear infinite;
  animation-timing-function: ${({ theme }) => theme.transitions.easeInQuart};
  animation-direction: alternate;
  animation-play-state: ${({ isStick }) => (isStick ? "running" : "paused")};
  span {
    text-align: center;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.fatfrank};
  }
  ${({ theme }) => theme.mq.xs} {
    z-index: ${({ theme }) => theme.zIndex.minimal};
    height: 115px;
    width: 115px;
    top: 100px;
    right: 30px;
    position: absolute;
    border-radius: 100px;
    font-size: 28px;
  }
`

const StyledLetsTalkStatic = styled(StyledLetsTalk)`
  border-radius: 5px;
  font-size: 16px;
  height: 40px;
  position: relative !important;
  right: auto;
  top: auto;
  width: 120px;
  padding: 0;
`

const StyledShape = styled(animated.div)`
  position: absolute;
  width: 200%;
  left: 50%;
  height: 100%;
  z-index: -1;
  background: ${({ cyan }) =>
    `linear-gradient(90deg, ${
      cyan
        ? "rgba(0, 200, 225, 0) 25%, rgba(0, 199, 225, 0.6) 50%"
        : "rgba(105, 36, 194, 0.5) 30%, rgba(0, 199, 225, 0.3) 50%"
    })`};
  ${({ theme }) => theme.mq.xs} {
    width: 300%;
    background: ${({ cyan }) =>
      `linear-gradient(90deg, ${
        cyan
          ? "rgba(0, 200, 225, 0) 25%, rgba(0, 199, 225, 0.6) 50%"
          : "rgba(0, 199, 225, 0.3) 65%, rgba(105, 36, 194, 0.5) 85%"
      })`};
    opacity: ${({ cyan }) => (cyan ? "0.75" : "1")};
  }
`

const StyledDescription = styled.h2`
  text-transform: none;
`

const StyledArrow = styled.button`
  background: none;
  border: none;
  padding: 1rem;
`

const StyledClutchMobile = styled.div`
  display: flex;
  padding: 0 1rem 2rem 1rem;
  background: ${({ theme }) =>
    `linear-gradient(to top, ${theme.color.cyan_600} 75%, transparent 75%, transparent 100%)`};

  ${({ theme }) => theme.mq.xxs} {
    flex-direction: column;
    align-items: center;
  }
`

const StyledClutchMobileLogo = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  margin-right: 2rem;

  ${({ theme }) => theme.mq.xxs} {
    margin: 0 0 15px 0;
  }
`

export {
  StyledFilter,
  StyledHero,
  StyledHeroContentBox,
  StyledLogo,
  StyledHeroDescription,
  StyledGatsbyImg,
  StyledVideo,
  StyledLetsTalk,
  StyledLetsTalkStatic,
  StyledShape,
  StyledDescription,
  StyledHeroContent,
  StyledMobileWrapper,
  StyledArrow,
  StyledClutchMobile,
  StyledClutchMobileLogo,
}
