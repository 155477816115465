import { useEffect, useState } from "react"

import { debounce, isString } from "lodash"

import { mq } from "styles/theme"

const resolveQuery = query => {
  if (isString(query)) return query
  const { bp, up = false } = query
  return `(${up ? "min" : "max"}-width: ${mq[bp]}px)`
}

/**
 *
 * @param {String|{ bp: string, up: boolean }} query
 * @param {{resizeListener: boolean}} config
 * @returns { boolean }
 */
const useMq = (query, { resizeListener }) => {
  const isCSR = typeof window !== "undefined"
  const mediaQuery = isCSR ? window.matchMedia(resolveQuery(query)) : null
  const getValue = () => (mediaQuery ? mediaQuery.matches : false)

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    if (mediaQuery && resizeListener) {
      const update = () => setValue(getValue)
      const debouncedUpdate = debounce(update, 100)
      window.addEventListener("resize", debouncedUpdate)
      return () => window.removeEventListener("resize", debouncedUpdate)
    }
    return null
  }, [])

  return value
}

export default useMq
