import { Link } from "gatsby"
import styled from "styled-components"

export default styled(Link)`
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  text-decoration: none;
  padding: ${({ shrink }) => (shrink ? "10" : "20")}px;
  cursor: pointer;
  margin: ${({ shrink }) => (shrink ? "10" : "0")}px;
  ${({ theme }) => theme.mq.xs} {
    font-size: 14px;
  }

  &.lets-talk {
    padding: 0;
    width: 96px;
    margin: 0;
  }

  &.v-hidden {
    visibility: hidden;
  }
`
