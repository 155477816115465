const mq = {
  l: 1900,
  m: 1410,
  s: 1160,
  xs: 890,
  xxs: 435,
}

const hexToRgba = (hex, alpha = 1) => {
  const matches = hex.match(/\w\w/g)
  if (matches) {
    const [r, g, b] = matches.map(x => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }
  return hex
}

export { mq, hexToRgba }

const theme = {
  color: {
    black: "#000000",
    blue_700: "#043752",
    blue_900: "#161129",
    graphite: "#262E39",
    dark: "#1a2028",
    purple: "#662d91",
    purple_700: "#662d91",
    purple_600: "#5b3e9a",
    purple_500: "#4f4fa3",
    purple_dark: "#2a1552",
    cyan_900: "#4F79BD",
    cyan_800: "#3884C3",
    cyan_700: "#208EC9",
    cyan_600: "#13A6D2",
    cyan_500: "#08b9db",
    cyan_400: "#00C8E1",
    cyan_300: "#03c2de",
    white_200: "#E5E5E5",
    white_000: "#FFFFFF",
    yellow_200: "#ffd800",
    yellow_400: "#ffbb00",
    gray_100: "#F8F8F8",
    gray_200: "#E2E2E2",
    gray_400: "#B6B6B6",
    text: {
      inverted: "#ffffff",
      default: "#000000",
    },
    facebook: "#4294FF",
  },
  gradient: null,
  shadow: {
    light: "2px 3px 8px 0px rgba(0, 0, 0, .1)",
    medium: "2px 3px 12px 0px rgba(0, 0, 0, .25)",
  },
  font: {
    azo_sans_web: '"azo-sans-web", sans-serif',
    azo_sans_uber: '"azo-sans-uber", sans-serif',
    fatfrank: "fatfrank, sans-serif",
  },
  mq: {
    l: `@media(max-width: ${mq.l}px)`,
    m: `@media(max-width: ${mq.m}px)`,
    s: `@media(max-width: ${mq.s}px)`,
    xs: `@media(max-width: ${mq.xs}px)`,
    xxs: `@media(max-width: ${mq.xxs}px)`,
    l_up: `@media(min-width: ${mq.l}px)`,
    m_up: `@media(min-width: ${mq.m}px)`,
    s_up: `@media(min-width: ${mq.s}px)`,
    xs_up: `@media(min-width: ${mq.xs}px)`,
    xxs_up: `@media(min-width: ${mq.xxs}px)`,
  },
  container: {
    xxl: 1520,
    xl: 1440,
    l: 1100,
    m: 890,
    s: 680,
    xs: 475,
    xxs: 360,
  },
  zIndex: {
    minimal: 1,
    header: 100,
    overlay: 1000,
    dialog: 1001,
  },
  transitions: {
    easeInQuart: "cubic-bezier(0.895, 0.03, 0.685, 0.22)",
  },
  size: {
    header: 64,
  },
}

theme.gradient = {
  linear: {
    cyan_dark_to_light: `
    background: ${theme.color.cyan_600};
    background: linear-gradient(
    93deg,
      ${theme.color.cyan_700} 0%,
      ${theme.color.cyan_300} 100%
    );
  `,
    cyan_light_to_dark: `
    background: ${theme.color.cyan_600};
    background: linear-gradient(
    93deg,
      ${theme.color.cyan_300} 0%,
      ${theme.color.cyan_700} 100%
    );
  `,
    cyan_light_to_medium_dark: `
    background: ${theme.color.cyan_600};
    background: linear-gradient(
    90deg,
      ${theme.color.cyan_300} 0%,
      ${theme.color.cyan_600} 100%
    );
  `,
    cyan_light_to_purple: `
    background: ${theme.color.cyan_600};
    background: linear-gradient(
    90deg,
      ${theme.color.cyan_300} 0%,
      ${theme.color.purple_700} 100%
    );
  `,
    purple_to_cyan: `
    background: ${theme.color.purple_dark};
    background: linear-gradient(
    90deg,
      ${theme.color.purple_dark} 0%,
      ${theme.color.cyan_700} 100%
    );
  `,
    purple_to_purple_dark: `
    background: ${theme.color.purple};
    background: linear-gradient(
    90deg,
      ${theme.color.purple} 0%,
      ${theme.color.purple_dark} 100%
    );
  `,
    purple_dark_to_purple: `
    background: ${theme.color.purple_dark};
    background: linear-gradient(
    90deg,
      ${theme.color.purple_dark} 0%,
      ${theme.color.purple} 100%
    );
  `,
    yellow_dark_to_yellow: `
    background: ${theme.color.yellow_400};
    background: linear-gradient(
    90deg,
      ${theme.color.yellow_400} 0%,
      ${theme.color.yellow_200} 100%
    );
  `,
    yellow_to_yellow_dark: `
    background: ${theme.color.yellow_200};
    background: linear-gradient(
    90deg,
      ${theme.color.yellow_200} 0%,
      ${theme.color.yellow_400} 100%
    );
  `,
    purple_to_green: `
    background: ${theme.color.purple_dark};
    background: linear-gradient(
    90deg,
      ${theme.color.purple_dark} 0%,
      #0F6373 100%
    );`,
  },
  mixed: {
    blue: `
    background: ${theme.color.blue_900};
    background: linear-gradient(
    -90deg,
      ${theme.color.blue_700} 20%,
      ${theme.color.blue_900} 100%
    ),
    radial-gradient(
    circle,
      ${theme.color.blue_700} 30%,
      ${theme.color.blue_900} 100%
    );
    background-blend-mode: lighten;
  `,
  },
}

export default theme
