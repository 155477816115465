/* eslint-disable react/no-danger */
import React, { useState } from "react"

import { Link, graphql, useStaticQuery } from "gatsby"
import Markdown from "marked-react"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled, { css } from "styled-components"

import JmrLogo from "assets/svg/logo_jmr.svg"
import ClutchReview from "components/common/ClutchReview"
import Section from "components/common/Section"
import Text from "components/common/Text"
import WindowWidthTernaryConditional from "components/common/WindowWidthTernaryConditional"

const BREAK_POINTS = {
  l: 1900,
  m: 1410,
  s: 1160,
  xs: 890,
  xxs: 435,
}

const StyledJmrLogo = styled(JmrLogo)`
  max-width: 180px;
  margin-bottom: 2rem;
`

const StyledTextGrid = styled.div`
  line-height: 2.5rem;
  display: grid;
  grid-gap: 1rem;
  align-items: flex-start;
  align-self: flex-start;

  ${({ theme }) => theme.mq.s} {
    justify-content: center;
  }
`

const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.container.xl}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 18px;

  ${({ theme }) => theme.mq.s} {
    padding: 0;
  }
`

const StyledGrid = styled.div`
  display: grid;
  grid-column-gap: 2.5rem;
  grid-row-gap: 7.5rem;
  grid-template-columns: 5fr 4fr 5fr 4fr 4fr;

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: auto auto;
    grid-row-gap: 4rem;

    a {
      text-align: center;
    }
  }
`

const doubleColumnStyles = css`
  ${({ theme }) => theme.mq.s} {
    grid-column: 1/3;
    max-width: ${({ theme }) => theme.container.xs}px;
    display: grid;
    justify-self: center;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    padding: 0 2rem;
  }
  ${({ theme }) => theme.mq.s} {
    max-width: ${({ theme }) => theme.container.xxs}px;
  } ;
`

const StyledDoubleColumnWrapper = styled.div`
  ${doubleColumnStyles};
`

const StyledSubgrid = styled.div`
  display: grid;
  grid-gap: 3rem;
  align-items: flex-start;
  align-self: flex-start;
  ${({ twoColumns }) => twoColumns && doubleColumnStyles}

  ${({ theme }) => theme.mq.s} {
    grid-template-columns: 1fr;
    grid-column: 1/3;
  }
`

const StyledAddressBlock = styled.div`
  line-height: 2.5rem;

  ${({ theme }) => theme.mq.s} {
    grid-column: 1/3;
    text-align: center;
  } ;
`

const StyledImg = styled.img`
  width: 32px;
  height: 32px;
`

const StyledLink = styled.a`
  display: block;
  color: ${({ theme }) => theme.color.cyan_500};
  transition: color 0.2s linear;
  will-change: color;

  &:hover {
    color: ${({ theme }) => theme.color.cyan_900};
  }

  ${({ theme }) => theme.mq.s} {
    text-align: center;
  } ;
`

const query = graphql`
  query FooterQuery {
    strapiFooter {
      Column1 {
        address
      }
      Column2 {
        company_data
        email
        phone
      }
      Link1 {
        title
        url
      }
      Link2 {
        title
        url
      }
      social_media {
        url
        image {
          localFile {
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

const localMarkdown = text => <Markdown>{text}</Markdown>

const AddressBlock = data => {
  const { address } = data

  return (
    <StyledAddressBlock>
      <StyledJmrLogo />
      <WindowWidthTernaryConditional
        breakpoint={BREAK_POINTS.s}
        lower={
          <Text align="center" fontSize="1.6rem" inverted>
            {localMarkdown(address)}
          </Text>
        }
        upper={<Text inverted>{localMarkdown(address)}</Text>}
      />
    </StyledAddressBlock>
  )
}

const ContactBlock = data => {
  const { email, phone } = data
  return (
    <StyledTextGrid>
      <Text bold color="cyan" fontSize="1.8rem">
        <StyledLink href={`mailto: ${email}`} id="footer-email-click" rel="noreferrer" target="_blank">
          {email}
        </StyledLink>
      </Text>
      <Text fontSize="1.8rem" inverted>
        <StyledLink href={`tel: ${email}`} rel="noreferrer" target="_blank">
          {phone}
        </StyledLink>
      </Text>
    </StyledTextGrid>
  )
}
const CompanyDataBlock = companyData => {
  const { company } = companyData
  return (
    <StyledTextGrid>
      <WindowWidthTernaryConditional
        breakpoint={BREAK_POINTS.s}
        lower={
          <Text align="center" fontSize="1.6rem" inverted>
            <div>{localMarkdown(company)}</div>
          </Text>
        }
        upper={<Text inverted>{localMarkdown(company)}</Text>}
      />
    </StyledTextGrid>
  )
}
const SocialMediaWrapper = styled.div`
  ${({ theme }) => theme.mq.s} {
    background: transparent
      linear-gradient(270deg, rgba(0, 200, 225, 0.28) 0%, rgba(33, 102, 183, 0.28) 58%, rgba(65, 4, 141, 0.28) 100%);
    grid-column: 1/3;
    width: 100%;
  }
`
const SocialMediaContent = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1.5rem;
  align-items: center;
  ${({ theme }) => theme.mq.s} {
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }

  ${({ theme }) => theme.mq.s} {
    a {
      text-align: center;
    }
  }

  ${({ theme }) => theme.mq.s} {
    display: flex;
    justify-content: center;

    a {
      margin: 0 10px;
    }
  }
`

const SocialMediaBlock = data => {
  const { social_media } = data
  return (
    <SocialMediaWrapper>
      <SocialMediaContent>
        {social_media.map(item => (
          <a href={item.url} rel="noreferrer" target="_blank">
            <StyledImg alt="social media" loading="lazy" src={item.image.localFile.url} />
          </a>
        ))}
      </SocialMediaContent>
    </SocialMediaWrapper>
  )
}
const LinksBlock = ({ links, splitable }) => {
  if (splitable) {
    const linksLength = links.length
    const left = links.slice(0, linksLength / 2)
    const right = links.slice(linksLength / 2)
    return (
      <WindowWidthTernaryConditional
        breakpoint={BREAK_POINTS.s}
        lower={
          <>
            <StyledDoubleColumnWrapper>
              <StyledTextGrid>
                {left.map(({ title, url }) => (
                  <Text as={Link} fontSize="1.6rem" inverted margin="0 0 1rem 0" to={url}>
                    {title}
                  </Text>
                ))}
              </StyledTextGrid>
              <StyledTextGrid>
                {right.map(({ title, url }) => (
                  <Text as={Link} fontSize="1.6rem" inverted margin="0 0 1rem 0" to={url}>
                    {title}
                  </Text>
                ))}
              </StyledTextGrid>
            </StyledDoubleColumnWrapper>
          </>
        }
        upper={
          <StyledTextGrid>
            {links.map(({ title, url }) => (
              <Text as={Link} fontSize="1.2rem" inverted margin="0 0 1rem 0" to={url}>
                {title}
              </Text>
            ))}
          </StyledTextGrid>
        }
      />
    )
  }

  return (
    <StyledTextGrid>
      {links.map(({ title, url }) => (
        <WindowWidthTernaryConditional
          breakpoint={BREAK_POINTS.s}
          lower={
            <Text as={Link} fontSize="1.6rem" inverted margin="0 0 1rem 0" to={url}>
              {title}
            </Text>
          }
          upper={
            <Text as={Link} fontSize="1.2rem" inverted margin="0 0 1rem 0" to={url}>
              {title}
            </Text>
          }
        />
      ))}
    </StyledTextGrid>
  )
}

LinksBlock.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string, url: PropTypes.string })).isRequired,
  splitable: PropTypes.bool.isRequired,
}

const FooterBody = () => {
  const { strapiFooter } = useStaticQuery(query)
  const [isVisible, setIsVisible] = useState(false)
  const contentSpring = useSpring({ config: config.stiff, opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 300 })

  return (
    <Section color="transparent" delay="10%" intersected onIntersectChange={setIsVisible} padding_s="4rem 0">
      <animated.div style={{ transform: contentSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`) }}>
        <StyledContainer>
          <StyledGrid>
            <AddressBlock address={strapiFooter.Column1.address} />
            <StyledSubgrid>
              <ContactBlock email={strapiFooter.Column2.email} phone={strapiFooter.Column2.phone} />
              <CompanyDataBlock company={strapiFooter.Column2.company_data} />
            </StyledSubgrid>
            <WindowWidthTernaryConditional
              breakpoint={BREAK_POINTS.s}
              lower={<SocialMediaBlock social_media={strapiFooter.social_media} />}
              upper={
                <StyledSubgrid twoColumns>
                  <ClutchReview />
                  <SocialMediaBlock social_media={strapiFooter.social_media} />
                </StyledSubgrid>
              }
            />
            <WindowWidthTernaryConditional
              breakpoint={BREAK_POINTS.s}
              lower={
                <StyledDoubleColumnWrapper>
                  {strapiFooter.Link1 && <LinksBlock links={strapiFooter.Link1} splitable={false} />}
                  {strapiFooter.Link2 && <LinksBlock links={strapiFooter.Link2} splitable={false} />}
                </StyledDoubleColumnWrapper>
              }
              upper={
                <>
                  {strapiFooter.Link1 && <LinksBlock links={strapiFooter.Link1} splitable={false} />}
                  {strapiFooter.Link2 && <LinksBlock links={strapiFooter.Link2} splitable={false} />}
                </>
              }
            />
          </StyledGrid>
        </StyledContainer>
      </animated.div>
    </Section>
  )
}

export default FooterBody
