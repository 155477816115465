/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react"

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import classNames from "classnames"
import { Link } from "gatsby"
import { useSpring, animated, config } from "react-spring"
import styled from "styled-components"

import LogoJmr from "assets/svg/logo_jmr.svg"
import Container from "components/common/Container"
import { StyledLetsTalkStatic } from "components/pages/home/Hero/Hero.styles"
import urls from "config/urls"
import { useGlobalDataContext } from "context/GlobalDataContext"
import spacesToNbsp from "helpers/spacesToNbsp"
import useMq from "helpers/useMq"
import { hexToRgba } from "styles/theme"

import Hamburger from "../Hamburger"
import NavigationLink from "./_components/NavigationLink"

const StyledHeader = styled.header`
  width: 100%;
  background: ${({ theme }) => hexToRgba(theme.color.dark, 0.86)};
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.header};
`

const StyledLogo = styled(Link)`
  margin: 15px 0;
  ${({ theme }) => theme.mq.xs} {
    margin-left: 0;
  }
`

const StyledLogoJmr = styled(LogoJmr)`
  width: 126px;
  height: 34px;
  display: block;
  max-width: 100%;
  max-height: 100%;
`

const StyledNavigationLinkWrapper = styled(animated.nav)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mq.xs} {
    position: absolute;
    top: ${({ theme }) => `${theme.size.header}px`};
    left: -50px;
    width: calc(100% + 100px);
    height: ${({ theme }) => `calc(100vh - ${theme.size.header}px)`};
    background: ${({ theme }) => hexToRgba(theme.color.dark, 0.86)};
    flex-direction: column;
    padding-right: 50px;
    box-sizing: content-box;
    transform: translate3d(120%, 0px, 0px);
  }
`

const StyledBackdrop = styled(animated.div)`
  will-change: height, width, top, left;
  display: none;
  @media (hover: hover) {
    display: block;
    position: absolute;
    background: ${({ theme }) => theme.color.cyan_400};
    height: 50px;
    width: 100px;
    z-index: -1;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const backdropInit = { x: 1000, y: -100, width: 100, height: 30, init: true }

const urlsEntries = Object.entries(urls).filter(([, value]) => !!value.menu)

const Header = () => {
  const isBrowser = () => typeof window !== "undefined"
  const currentUrl = isBrowser() && window.location.pathname

  const {
    letsTalk: { isStick },
  } = useGlobalDataContext()
  const isXs = useMq({ bp: "xs" }, { resizeListener: true })

  // HAMBURGER SERVICE
  const bodyScrollLockElement = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onHamburgerClick = () => setIsMenuOpen(prev => !prev)
  useEffect(() => {
    if (isMenuOpen) disableBodyScroll(bodyScrollLockElement)
    else enableBodyScroll(bodyScrollLockElement)
  }, [isMenuOpen])
  const navigationSpring = useSpring({
    config: config.stiff,
    left: isMenuOpen ? 0 : 120,
  })

  // DESKTOP SERVICE
  const [backdrop, setBackdrop] = useState(backdropInit)
  const [init, setInit] = useState(true)
  const backdropSpring = useSpring({
    config: config.stiff,
    left: backdrop.x,
    top: backdrop.y + backdrop.height / 6,
    width: backdrop.width,
    height: backdrop.height / 1.5,
    immediate: key => {
      if (init && !backdrop.init) {
        setInit(false)
        return key === "left"
      }
      return false
    },
  })
  const onLinkEnter = ({ target }) => {
    const client = target.getBoundingClientRect()
    setBackdrop({ x: client.x, y: client.y, width: client.width, height: client.height, init: false })
  }
  const onLinkLeave = () => setBackdrop({ ...backdropInit, width: backdrop.width, x: backdrop.x })
  const onWrapperLeave = () => setInit(true)

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledLogo aria-label="Home page" to="/">
          <StyledLogoJmr alt="JMR logo" />
        </StyledLogo>

        <StyledNavigationLinkWrapper
          ref={bodyScrollLockElement}
          isMenuOpen={isMenuOpen}
          onMouseLeave={onWrapperLeave}
          style={{
            ...(isXs && { transform: navigationSpring.left.interpolate(dx => `translate3d(${dx}%,0,0)`) }),
          }}
        >
          {urlsEntries.map(([key, { url, label }]) =>
            key === "contact" ? (
              ((isBrowser() && window === undefined) || currentUrl) === "/" ? (
                <NavigationLink
                  key={key}
                  id={key}
                  onClick={onHamburgerClick}
                  onMouseEnter={onLinkEnter}
                  onMouseLeave={onLinkLeave}
                  shrink={isStick && key === "contact"}
                  to={url}
                >
                  Contact
                </NavigationLink>
              ) : (
                <NavigationLink
                  key={key}
                  className={classNames("lets-talk")}
                  id={key}
                  onClick={onHamburgerClick}
                  shrink={isStick && key === "contact"}
                  to={url}
                >
                  <StyledLetsTalkStatic key={key} id={key} isStick={false}>
                    <span>Contact</span>
                  </StyledLetsTalkStatic>
                </NavigationLink>
              )
            ) : (
              <NavigationLink
                key={key}
                id={key}
                onClick={onHamburgerClick}
                onMouseEnter={onLinkEnter}
                onMouseLeave={onLinkLeave}
                shrink={isStick && key === "contact"}
                to={url}
              >
                {spacesToNbsp(label)}
              </NavigationLink>
            )
          )}
          <StyledBackdrop
            style={{
              left: backdropSpring.left,
              top: backdropSpring.top,
              width: backdropSpring.width,
              height: backdropSpring.height,
            }}
          />
        </StyledNavigationLinkWrapper>
        <Hamburger isMenuOpen={isMenuOpen} onClick={onHamburgerClick} />
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header
