import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import LogoClutch from "assets/svg/logo_clutch.svg"
import IconStars from "assets/svg/stars.svg"

const StyledWrapper = styled.a`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  grid-column: span 2;
  align-items: flex-end;
`

const StyledColumn = styled.div`
  display: grid;
  grid-gap: 1rem;
  align-items: flex-end;
`

const StyledText = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 1.2rem;
`

const StyledLogoClutch = styled(LogoClutch)`
  max-width: 140px;
  width: 100%;

  ${({ theme }) => theme.mq.xs} {
    width: 90px;
  }
`

const StyledIconStars = styled(IconStars)`
  width: 100px;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mq.xs} {
    width: 80px;
  }
`

const query = graphql`
  query ClutchQuery {
    strapiCommon {
      Clutch {
        reviews_number
      }
    }
  }
`

const ClutchReview = () => {
  const { strapiCommon } = useStaticQuery(query)
  return (
    <StyledWrapper href="https://clutch.co/profile/jmr-technologies?utm_source=widget" target="_blank">
      <StyledColumn>
        <StyledText>reviewed on</StyledText>
        <StyledLogoClutch />
      </StyledColumn>
      <div>
        <StyledIconStars />
        <StyledText>{`${strapiCommon.Clutch.reviews_number} reviews`}</StyledText>
      </div>
    </StyledWrapper>
  )
}

ClutchReview.defaultProps = {}

ClutchReview.propTypes = {}

export default ClutchReview
