import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import themeObj from "styles/theme"

const StyledContainer = styled.div`
  max-width: ${({ theme, size }) => (size ? theme.container[size] : theme.container.xl)}px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ noPadding }) => (noPadding ? 0 : "0 2.5rem")};
  ${({ theme }) => theme.mq.xs} {
    overflow: hidden;
  }
`

const Container = ({ children, noPadding, ...rest }) => (
  <StyledContainer noPadding={noPadding} {...rest}>
    {children}
  </StyledContainer>
)

Container.propTypes = {
  size: PropTypes.oneOf(Object.keys(themeObj.container)),
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
}

Container.defaultProps = {
  size: "xl",
  noPadding: false,
}

export default Container
