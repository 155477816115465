import React from "react"

import styled from "styled-components"

// import LetsTalkBar from "components/common/LetsTalkBar"

import Notifications from "../../../Notifications"
import FooterBody from "./_components/FooterBody"
import FooterBottomBar from "./_components/FooterBottomBar"

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.gradient.linear.purple_to_cyan};
`

const Footer = () => (
  <StyledFooter>
    {/* <LetsTalkBar /> */}
    <FooterBody />
    <FooterBottomBar />
    <Notifications />
  </StyledFooter>
)

export default Footer
