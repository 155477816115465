export default {
  services: {
    url: "/services/",
    label: "Services",
    menu: true,
  },
  portfolio: {
    url: "/portfolio/",
    label: "Portfolio",
    menu: true,
  },
  about: {
    url: "/about-us/",
    label: "About us",
    menu: true,
  },
  how_we_work: {
    url: "/how-we-work/",
    label: "How we work",
    menu: true,
  },
  blog: {
    url: "/blog/",
    label: "Blog",
    menu: true,
  },
  careers: {
    url: "/careers/",
    label: "Careers",
    menu: true,
  },
  contact: {
    url: "/contact/",
    label: "Contact us",
    menu: true,
  },
  mvp: {
    url: "/building-an-mvp/",
    label: "mvp",
  },
  privacyPolicy: {
    url: "/privacy-policy/",
    label: "Privacy policy",
  },
  teamExtension: {
    url: "/team-extension/",
    label: "Team extension",
  },
  portfolioElement: {
    url: "/portfolio/",
  },
  servicesElement: {
    url: "/services/",
  },
}
