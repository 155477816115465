import React from "react"

import PropTypes from "prop-types"
import { animated, useSpring, config } from "react-spring"
import styled from "styled-components"

import themeObject from "styles/theme"

const StyledButton = styled.button`
  display: none;
  background: none;
  border: none;
  padding: 15px;
  margin-right: -15px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  z-index: 100;
  justify-self: right;
  ${({ theme }) => theme.mq.xs} {
    display: flex;
  }
  &:focus {
    border: none;
    outline: none;
  }
`

const openedConfig = {
  top: "translate(0, 3) rotate(0)",
  center: "translate(0, 14) rotate(0) scale(1)",
  bottom: "translate(0, 26) rotate(0)",
}

const closedConfig = {
  top: "translate(4, 25) rotate(-45)",
  center: "translate(20, 14) rotate(0) scale(0)",
  bottom: "translate(5, 4) rotate(45)",
}

const rectProps = {
  width: "30",
  height: "2",
  fill: themeObject.color.white_200,
}

const Hamburger = ({ isMenuOpen, ...rest }) => {
  const { top, center, bottom } = useSpring({
    to: isMenuOpen ? closedConfig : openedConfig,
    config: config.stiff,
  })
  return (
    <StyledButton aria-label="open-navigation" type="button" {...rest}>
      <svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
        <animated.rect {...rectProps} transform={top} />
        <animated.rect {...rectProps} transform={center} />
        <animated.rect {...rectProps} transform={bottom} />
      </svg>
    </StyledButton>
  )
}

Hamburger.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
}

export default Hamburger
