import React, { useEffect, useRef } from "react"

import PropTypes from "prop-types"
import { animated, config, useSpring } from "react-spring"
import styled from "styled-components"

import useIntersect from "helpers/useIntersect"

const COLORS = {
  WHITE: "white",
  CYAN: "cyan",
  CYAN_INVERTED: "cyan_inverted",
  PURPLE: "purple",
  GRAPHITE: "graphite",
  GRAY: "gray",
  TRANSPARENT: "transparent",
  NAVY: "navy",
  YELLOW: "yellow",
}

const resolveColor = (theme, color, customColor) => {
  if (customColor) return customColor
  switch (color) {
    case COLORS.CYAN:
      return { background: theme.gradient.linear.cyan_dark_to_light, header: theme.color.text.inverted }
    case COLORS.CYAN_INVERTED:
      return { background: theme.gradient.linear.cyan_light_to_dark, header: theme.color.text.inverted }
    case COLORS.PURPLE:
      return { background: theme.gradient.linear.purple_dark_to_purple, header: theme.color.text.inverted }
    case COLORS.GRAPHITE:
      return { background: theme.color.graphite, header: theme.color.text.inverted }
    case COLORS.TRANSPARENT:
      return { background: "transparent", header: theme.color.text.default }
    case COLORS.GRAY:
      return { background: theme.color.gray_100, header: theme.color.text.default }
    case COLORS.NAVY:
      return { background: theme.gradient.mixed.blue, header: theme.color.text.inverted }
    case COLORS.YELLOW:
      return { background: theme.gradient.linear.yellow_dark_to_yellow, header: theme.color.text.inverted }
    default:
      return { background: theme.color.white_000, header: theme.color.text.default }
  }
}

const StyledSection = styled.section`
  padding: ${({ padding }) => padding};
  overflow: hidden;
  background: ${({ theme, color, customColor }) => resolveColor(theme, color, customColor).background};

  ${({ theme }) => theme.mq.xs} {
    padding: ${({ padding }) => (padding && padding === "0" ? padding : "4rem 0")};
  }

  h2 {
    font-size: 3.6rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: ${({ titleMargin }) => titleMargin};
    color: ${({ theme, color, customColor }) => resolveColor(theme, color, customColor).header};
    display: ${({ hideTitleOnDesktop }) => (hideTitleOnDesktop ? "none" : "block")};

    ${({ theme }) => theme.mq.xs} {
      display: block;
      font-size: 2.4rem;
      padding: 0 30px;
      margin-bottom: 3rem;
    }
  }
`

StyledSection.defaultProps = {
  padding: "7rem 0",
  titleMargin: "7rem",
}

const IntersectedSection = ({ onIntersectChange, children, delay, animatedTitle, hideTitleOnDesktop, ...props }) => {
  const elementRef = useRef(null)
  const [inView] = useIntersect(elementRef, { threshold: 0, rootMargin: delay ? `-${delay} 0%` : undefined })
  useEffect(() => {
    onIntersectChange(inView)
  }, [inView, onIntersectChange])
  const titleSpring = useSpring({ config: config.stiff, x: inView ? 0 : -75 })
  return (
    <StyledSection ref={elementRef} hideTitleOnDesktop={hideTitleOnDesktop} {...props}>
      {animatedTitle && (
        <animated.h2
          style={{
            transform: titleSpring.x.interpolate(dx => `translate3d(${dx}vw,0,0)`),
            willChange: "transform",
            ...titleSpring,
          }}
        >
          {animatedTitle}
        </animated.h2>
      )}
      {children}
    </StyledSection>
  )
}

IntersectedSection.propTypes = {
  onIntersectChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  delay: PropTypes.string,
  animatedTitle: PropTypes.string,
  hideTitleOnDesktop: PropTypes.bool,
}

IntersectedSection.defaultProps = {
  delay: undefined,
  animatedTitle: undefined,
  hideTitleOnDesktop: false,
}

const Section = ({ intersected, onIntersectChange, children, animatedTitle, ...props }) =>
  (intersected && onIntersectChange) || animatedTitle ? (
    <IntersectedSection animatedTitle={animatedTitle} onIntersectChange={onIntersectChange} {...props}>
      {children}
    </IntersectedSection>
  ) : (
    <StyledSection {...props}>{children}</StyledSection>
  )

Section.propTypes = {
  intersected: PropTypes.bool,
  onIntersectChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(COLORS)),
  customColor: PropTypes.shape({
    background: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
  }),
  animatedTitle: PropTypes.string,
  hideTitleOnDesktop: PropTypes.bool,
}

Section.defaultProps = {
  intersected: false,
  onIntersectChange: () => {},
  color: COLORS.WHITE,
  animatedTitle: undefined,
  hideTitleOnDesktop: false,
  customColor: null,
}

export default Section
