import React from "react"

import styled from "styled-components"

import Container from "components/common/Container"
import Text from "components/common/Text"

import FooterBar from "./FooterBar"

const StyledContentWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mq.xxs} {
    flex-direction: column-reverse;
    align-items: center;

    p,
    a {
      padding: 10px 0;
      font-size: 16px;
    }
  }
`

const FooterBottomBar = () => (
  <FooterBar>
    <Container size="l">
      <StyledContentWrapper>
        <Text inverted>Copyright {new Date().getFullYear()} by JMR</Text>
        <Text element="a" href="/privacy-policy/" inverted rel="nofollow" target="_blank">
          Privacy Policy
        </Text>
      </StyledContentWrapper>
    </Container>
  </FooterBar>
)

export default FooterBottomBar
