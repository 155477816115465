/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React, { Suspense } from "react"

import { ThemeProvider } from "styled-components"
import "./src/styles/typekit.css"
import "./src/styles/reset.css"
import "./src/styles/global.css"
import "./src/styles/notifications.css"
import theme from "styles/theme"

import GlobalDataProvider from "context/GlobalDataContext"
import Header from "components/common/Layout/_components/Header"
import Footer from "components/common/Layout/_components/Footer"

const SiteWrapperLazy = React.lazy(() => import("components/common/Layout/_components/SiteWrapper"))

export const onServiceWorkerUpdateReady = () => window.location.reload()

export const onClientEntry = () => {
  window.addEventListener("beforeinstallprompt", e => e.preventDefault())
}

/* eslint-disable */
self.addEventListener("install", () => {
  self.skipWaiting()
})

// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
if (!("IntersectionObserver" in window)) {
  import(`intersection-observer`)
  console.log("# IntersectionObserver is polyfilled!")
}

export const wrapRootElement = ({ element }) => (
  <GlobalDataProvider>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </GlobalDataProvider>
)

export const wrapPageElement = ({ element }) => (
  <Suspense fallback="loading...">
    <SiteWrapperLazy>
      <Header />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WBMPRNC"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NQKDZV7"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      {element}
      <Footer />
    </SiteWrapperLazy>
  </Suspense>
)
