import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

const StyledBar = styled.div`
  background: transparent
    linear-gradient(270deg, rgba(0, 200, 225, 0.28) 0%, rgba(33, 102, 183, 0.28) 58%, rgba(65, 4, 141, 0.28) 100%);
`

const FooterBar = ({ children }) => <StyledBar>{children}</StyledBar>

FooterBar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FooterBar
